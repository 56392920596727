<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="
          !data.properties.hideLabel && data.properties.filed_content != 'Hide'
        "
      >
        <label for="text" v-if="hasLabel" :style="getLabelStyles">{{
           label||data.label 
        }}</label>
        <span
        v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
          </el-popover>
          </span>
      </el-col>
      <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span v-if="hasLabel" style="color: white; display: inline-block; height: 20px; opacity: 0;" ></span>
        <span
        v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
      <!-- <el-col :span="data.description ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          title="Help"
          trigger="click"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
      </el-col> -->

      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <!-- <el-date-picker
        v-if="getGlobalVariableById.value "
          prefix-icon="el-icon-date"
          v-model="getGlobalVariableById.value"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :readonly="true"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>  -->

        <el-date-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-date"
          v-model="form[this.data.key]"
          :readonly="readonly()"
          type="date"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
          @change="applyFormRules(time)"
          :picker-options="pickerOptions"
        >
        </el-date-picker>

        <el-date-picker
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
          @change="applyFormRules"
          :readonly="readonly()"
          :picker-options="pickerOptions"
          :class="{ 'highlighted-border': highlight }"
        >
        </el-date-picker>
        <p
          v-if="isView && data.properties.filed_content != 'Hide'"
          :style="getStyle"
        >
          <template v-if="form[data.key]">
            {{ form[data.key] | globalDateFormat }}
          </template>
          <template v-else>{{ data.properties && data.properties.tableDefaultValue && data.properties.tableDefaultValue !== '' ? data.properties.tableDefaultValue : '-' }}</template>
        </p>
      </el-col>
    </el-row>
    <!--<div class="form-group">
      <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
      <el-popover
        v-if="data.description"
        placement="top-start"
        title="Help"
        trigger="click"
        :content="data.description"
      >
        <i class="el-icon-info" slot="reference"></i>
      </el-popover>

      <el-date-picker
        v-if="!isView"
        prefix-icon="el-icon-date"
        v-model="form[data.key]"
        type="date"
          :placeholder="data.placeholder || data.label" 
        :style="getStyle"
        :disabled="checkReadonly()"
      ></el-date-picker>

      <p v-if="isView" :style="getStyle">
        <template v-if="form[data.key]">{{form[data.key] | moment("YYYY-DD-MM")}}</template>
        <template v-else>-</template>
      </p>
    </div>-->
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

import { mapGetters } from "vuex";
import { bus } from "../../../main";
import moment from "moment";
// import momentTimezone from "moment-timezone";
export default {
  name: "templates-formComponentsExecute-DateExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "form",
    "label",
    "isView",
    "hasLabel",
    "isFromDocument",
    "checkIsDisabled",
    "profilePage",
    "dataTableRowIndex",
    "checkhideFields",
    "colorFields",
    "highlight"
  ],

  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px; width: 100%`;
    },
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),

    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    pickerOptions() {
      if (
  this.data &&
  this.data.properties &&
  this.data.properties.date_settings &&
  this.data.properties.date_settings === "Past Dates" &&
  this.data.properties.selected_weekdays
) {
  const selectedWeekdays = this.data.properties.selected_weekdays;
  return {
    disabledDate: (date) => {
      const currentDate = new Date();
      if (selectedWeekdays.length > 0) {
        return (
          date.getTime() >= currentDate.getTime() ||
          !selectedWeekdays.includes(date.getDay())
        );
      } else {
        return date.getTime() >= currentDate.getTime();
      }
    },
  };
}  else if (
    this.data &&
    this.data.properties &&
    this.data.properties.date_settings &&
    this.data.properties.date_settings === "Future Dates" &&
    this.data.properties.selected_weekdays
  ) {
    const selectedWeekdays = this.data.properties.selected_weekdays;
    return {
      disabledDate: (date) => {
        const currentDate = new Date();
        if (selectedWeekdays.length > 0) {
        return (
          date.getTime() < currentDate.getTime() ||
          !selectedWeekdays.includes(date.getDay())
        );
      } else {
        return date.getTime() < currentDate.getTime();
      }
      },
    };
  } 
    else if (
    this.data &&
    this.data.properties &&
    this.data.properties.date_settings &&
    this.data.properties.date_settings === "WeekDays"
  ) {
    let selectedWeekdays = this.data.properties.selected_weekdays;
    return {
      disabledDate: (date) => {
        const day = date.getDay();
        return !selectedWeekdays.includes(day);
      },
    };
  }
  else if (
        this.data &&
        this.data.properties &&
        this.data.properties.date_settings &&
        this.data.properties.date_settings === "Range"  &&
        this.data.properties.selected_weekdays
      ) {
        let start = this.data.properties.date_strat_range;
        let end = this.data.properties.date_end_range;
        const selectedWeekdays = this.data.properties.selected_weekdays;
        return {
          disabledDate(date) {
            if (start && end) {
              if (selectedWeekdays.length > 0) {
              return !(
                date.getTime() > new Date(start).getTime() &&
                date.getTime() < new Date(end).getTime() && selectedWeekdays.includes(date.getDay())
              );
            }else{
              return !(
                date.getTime() > new Date(start).getTime() &&
                date.getTime() < new Date(end).getTime())
            }}
            return false;
          },
        };
      } else {
    return {};
  }
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  data() {
    return {
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    this.loading = false;
    this.$emit("applyFormRules");
    if (Array.isArray(this.form[this.data.key])) {
      const arrayAsString = this.form[this.data.key].join(', ');
      this.form[this.data.key] = arrayAsString;
    }
  },
  methods: {
    defaultMethod() {
    if (
      this.data.default_value &&
      this.form &&
      this.data.key &&
      !this.form[this.data.key]
    ) {
      let date;
      if (this.data.properties.defaultTodayDate && this.form[this.data.key] === undefined) {
        date = moment();
      } else {
        date = moment(this.data.default_value);
      }
      this.$set(
        this.form,
        this.data.key,
        moment(date).startOf("day").toISOString() // Format as 'YYYY-MM-DD hh:mm:ss'
      );
    } else if (
      !this.form[this.data.key] &&
      this.data.default_mapped_field &&
      this.form[this.data.default_mapped_field]
    ) {
      this.$set(
        this.form,
        this.data.key,
        this.form[this.data.default_mapped_field]
      );
    }
    if (
      this.data.properties.autofill_response === true &&
      !this.isView &&
      !this.form[this.data.key]
    ) {
      this.$set(this.form, this.data.key, moment().startOf("day").toISOString());
    }
  },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "DATE") {
          if (this.getGlobalVariableById.datestamp) {
            this.isList = true;
            this.form[this.data.key] = new Date().toLocaleDateString();
            bus.$emit("global-date", this.form[this.data.key]);
          } else {
            this.isList = true;
            this.form[this.data.key] = this.getGlobalVariableById.value;
            bus.$emit("global-date", this.form[this.data.key]);
          }
        }
      }
    },
    //
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss">

.el-icon-date:before {
    align-items: center;
    justify-content: center;
    display: flex;
}
</style>
